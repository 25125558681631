import React, { useRef, useEffect } from 'react'
import BackgroundImage from 'gatsby-background-image'
import gsap from 'gsap'
import scrollTrigger from 'gsap/all'
import { SplitText } from 'gsap/SplitText'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { device } from '../styles/MediaQueries'
import { colors } from '../styles/Variables'
import { typography, Heading1, Heading2, PageHeroTitle } from '../styles/Typography'

gsap.registerPlugin(scrollTrigger, SplitText)

const HeroWrapper = styled.div` 
  width: 100vw;
  height: 75vh;
  /* min-height: 350px; */
  box-shadow: 0px 6px 17px 3px #ccc;
`

const bgImgStyles = {
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'top center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

// When you call the component Hero you must pass in imageData to use the background Image. So you'd make a graphql request and store the resulting image in a variable called imageData and pass it in as props

function Hero ({ description, title, imageData, children }) {
  const heroWrapperRef = useRef(null)

  // useEffect(() => {
  //   const tl = gsap.timeline()

  //     .from(heroWrapperRef.current, {
  //       height: 0,
  //       transformOrigin: 'top',
  //       ease: 'Power4.out',
  //       duration: 0.5
  //     })
  // }, [])

  // No longer need this static query as each page that wants to use Hero component must pass in imageData prop
  // const data = useStaticQuery(
  //   graphql`
  //     query {
  //       desktop: file(relativePath: { eq: "cookeatdiscover_2019_0230.jpg" }) {
  //         childImageSharp {
  //           fluid(quality: 90, maxWidth: 1920) {
  //             ...GatsbyImageSharpFluid_withWebp
  //           }
  //         }
  //       }
  //     }
  //   `
  // )

  // Set ImageData
  // const imageData = data.desktop.childImageSharp.fluid
  return (
    <HeroWrapper ref={heroWrapperRef}>
      <BackgroundImage
        fluid={imageData}
        style={bgImgStyles}
        className='hero-bg'
      >
        <PageHeroTitle><span>{children}</span></PageHeroTitle>
      </BackgroundImage>
    </HeroWrapper>
  )
}

// const StyledHero = styled(Hero)`
//     width: 100%;
// `

export default Hero
